.menu {

  border-radius: 2px;
  border-style: solid;
  border-width: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 70px #575757;
  left: 50%;
  max-height: 15em;
  min-width: 100%;
  padding: 0;
  position: absolute;
  top: calc(100% + 10px);
  transform: translateX(-50%);
  visibility: hidden;
  z-index: 100;
  overflow-y: scroll;
}

.menuBottom {
  composes: menu;
  visibility: visible;
}

.menuLeft {
  composes: menu;
  bottom: auto;
  left: -100%;
  top: 50%;
  transform: translateY(-50%);
  visibility: visible;
}

.menuRight {
  composes: menu;
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  visibility: visible;
}

.menuTop {
  composes: menu;
  bottom: calc(100% + 10px);
  top: auto;
  visibility: visible;
}

.list {
  overflow: auto;
}

.menuItem {
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #f0f0f0;
  background-color: #ffffff;
  cursor: pointer;
  min-height: 20px;
  padding: 0.5em;
}

.menuItem:hover {
  background-color: #fff6da;
}

.selectedMenuItem {
  composes: menuItem;
  background-color: #ffefbb;
}

.arrow {
  border-bottom-style: solid;
  border-bottom-width: 10px;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  height: 0;
  outline: none;
  pointer-events: none;
	position: absolute;
	left: calc(50% - 10px);
  width: 0;
}

.menuBottom .arrow {
  top: -14px;
}

.menuTop .arrow {
  top: calc(100% + 4px);
  transform: rotate(180deg);
}

.border {
  border: 2px solid red !important;
  text-decoration: line-through;
 }