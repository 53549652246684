.errorMessageContainer {
  color: #fff;
  background-color: #d42b1e;
  margin: 2em 0;
  padding: 0.5em;
  text-align: center;
}

.planeIcon {
  compose: planeIcon from './Login.module.css';
}

.verifyIconContainer {
  line-height: 40px;
  padding: 10px 0px;
  font-size: 14px;
}

.verifyIcon {
  font-size: 50px;
  cursor: pointer;
  padding-left: 90px;
}

.verifyTextLeft {
  padding-left: 45px;
}

.verifyTextRight {
  padding-left: 55px;
}

.verifyIconRight {
  font-size: 50px;
  padding-left: 115px;
  cursor: pointer;
}

.loginMain {
  margin: 0 3em 3em 3em;
  max-width: 30em;
  min-width: 20em;
}

.loginButton {
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1.5em;
}

.googleLoginButton {
  background-color: #575757;
  border: 2px solid #575757;
  color: #fff;
  padding: 0.5em 1.5em;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonSep {
  border-bottom: 1px solid #575757;
  height: 0;
  margin: 2em 0;
  position: relative;
  width: 100%;
}

.buttonSepLabel {
  background-color: #fff;
  color: #575757;
  font-family: 'proxima_novaregular';
  left: 50%;
  padding: 0 0.5em;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
}

.loginMainTitle {
  font-size: 1.5em;
  font-weight: 300;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  text-align: center;
  padding: 0.5em 0 1em 0;
}

.loginTitle {
  font-family: 'proxima_novaregular';
  font-size: 1em;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 7%;
}

.inputContainer {
  padding-bottom: 2em;
  text-transform: uppercase;
}

.loginField {
  align-items: stretch;
}

.loginFieldInvalid {
  composes: loginField;
}

.loginField label {
  font-family: 'proxima_novaregular';
  text-transform: uppercase;
}

.loginFieldInvalid input {
  border-color: #d42b1e;
}

.cookiesDisclaimer {
  color: #d42b1e;
  font-family: 'proxima_novaregular';
  padding: 2em 0 0 0;
  text-align: center;
}

.verifyingMsg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}

.verifyingMsg span {
  margin-left: 5px;
  margin-top: 2px;
}

.noClick {
  pointer-events: none;
  cursor: default;
}

.noClick .parentcontent {
  opacity: 0.5;
}