.removeContainer {
    display: flex;
    flex-direction: column;
}

.removeContainer .removeBody {
    padding: 1em;
    overflow: auto;
    text-align: center;
    font-size: 16px;
}

.removeContainer .removeButtons {
    display: flex;
    flex-direction: row;
    padding: 1em;
}

.removeContainer .removeButtons button {
    flex: 1;
}

.removeContainer .cancelButton {
    margin-right: 5px;
}

.removeContainer .confirmButton {
    margin-left: 5px;
}