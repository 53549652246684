.dashedLine {
  border-top: 2px dashed #ccc;
  width: 100%;
}

.solidLine {
  border-top: 2px solid #ccc;
  width: 100%;
}

.addOutlineButton {
  composes: ui-button-clear from '../../../../../index.css';
  margin: 0 auto;
  text-transform: uppercase;
}