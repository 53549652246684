.signIn {    
  margin: 0 3em 3em 3em;
  max-width: 30em;
  min-width: 20em;
}

.message {
  text-align: center;
}

/* Smaller than 450 (Phone) */
@media only screen and (max-width: 450px) {
  .signIn {
    height: 100%;
    margin: 0 0 3em 0;
    max-width: none;
    width: 100%;
  }
}