.editor {
  cursor: pointer;
  margin: 0.5em 0;
  padding: 1px;
  position: relative;
}

.editorSelected {
  composes: editor;
}

.editorSelected:after {
  background: rgba(0,0,0,0.1);
  background: linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 50%, rgba(255,255,255,1) 100%);
  bottom: -5px;
  box-shadow: inset 0 -1px 2px #fff;
  content: "";
  height: 5px;
  left: 2px;
  position: absolute;
  transform-origin: bottom;
  width: calc(100% - 20px);
  z-index: 2;
}

.editorInner {
  composes: flex-row from '../../../../index.css';
  align-items: center;
  background-color: #fff;
}

.editorLabel {
  color: #575757;
  padding: 0.5em 1em 0.5em 0;
  text-align: right;
  text-transform: uppercase;
  width: 10em;
}

.editorRemove {
  composes: flex-one from '../../../../index.css';
  text-align: center;
  text-transform: uppercase;
}