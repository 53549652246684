.patternCombo {}

.patternComboHidden {
  composes: patternCombo;
  display: none;
}

.header {
  font-size: 0.9em;
  padding: 7px 7px 9px 7px;
}

.headerHidden {
  composes: header;
  display: none;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
}

.selectedColors {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.colorChiclet {
  cursor: pointer;
  height: 63px;
  width: 63px;
  margin: 0;
  min-width: 63px;
  border: 1.5px solid rgb(204, 204, 204);
}

.activeColorChiclet {
  composes: colorChiclet;
  border-radius: 1em;
}

.thumbChiclet {
  cursor: pointer;
  height: 63px;
  width: 63px;
  margin: 0;
  min-width: 63px;
  border: 1.5px solid rgb(204, 204, 204);
}

.activeThumbChiclet {
  composes: thumbChiclet;
  border-radius: 1em;
}

.spacer {
  border-width: 0 1px;
  border-style: none solid;
  border-color: transparent #d0d0d0;
  height: 43px;
  margin: 10px;
  width: 43px;
}

.arrowPointer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.thumbsColors {
  display: flex;
  flex-direction: row;
}

.redCheckmark {
  color: red;
  font-size: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.5em;
}

@media only screen and (max-width: 750px) {
  .selectedColors {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}