.header {
  composes: flex-row from '../../../index.css';
}

html, body {
  margin: 0;
  padding: 0;
}

.floatingHeader {
  position: fixed; /* Keep it floating */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between; /* Space between title and buttons */
  align-items: center; /* Center items vertically */
}

.title {
  font-size: 1.5rem; /* Adjust size as needed */
  font-weight: bold;
}

.buttonGroup {
  display: flex;
  gap: 10px; /* Space between buttons */
}

.reviewItemContainer {
  margin-top: 10px;
}

.backButton {
  border: 0 none;
  color: #fff;
  text-transform: uppercase;
  height: 37px;
}

.reviewItemContainer {
  border: 1.2px solid #D5D9D9;
  border-radius: 4px;
  margin: 1em 0 1em 0;
  padding: 1em;
}

.reviewItemContainer > div {
  margin-left: 0px;
  margin-right: 0px;
}

.reviewItemContainer:last-child {
  margin: 1em 0 2em 0 !important;
}

.reviewItemContainer img {
  margin: 1em;
  height: 65%;
  width: 65%;
}

.footerBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 1em 0;
}


/* Smaller than 500 */
@media only screen and (max-width: 750px) {
  .reviewItemContainer {
    border: none;
    padding: 0;
  }

  .reviewItemContainer > div {
    padding: 1em;
  }

  .reviewItemContainer img {
    max-height: 90px;
    height: 100%;
    width: unset !important;
  }
}

