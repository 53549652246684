.radio {
  cursor: pointer;
  text-align: center;
  padding-right: 0.5em;
}

.radioIconChecked {
  color: #d42b1e;
}

.radioLabel {
  margin: 0.5 0.5 0.5 0.5em;
  padding-left: 0.5em;
}