.review {
  overflow: auto;
}

.addFooter {
  margin-bottom: 5.1em;
}

.body {
  border: 1.2px solid #D5D9D9;
  border-radius: 4px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.leftContent {
  width: 70%;
  padding: 1em;
}

.rightContent {
  width: 30%;
  padding: 1em;
}

.header {
  composes: flex-row from '../../../index.css';
  align-items: center;
  margin: 1em 0 0 0;
  padding: 0 1em;
}

.headerButton {
  background-color: transparent;
  border: 0 none;
  color: #ccc;
}

.headerText {
  composes: flex-one from '../../../index.css';
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
}

.header2 {
  composes: flex-row from '../../../index.css';
  align-items: center;
  border-radius: 4px;
  margin-bottom: 1em;
}

.header2Text {
  composes: flex-one from '../../../index.css';
  color: #fff;
  font-size: 1.5em;
  text-transform: uppercase;
  padding-left: 1em;
}

.addProductRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em 0 1em 0;
}

.addBtn {
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  text-transform: uppercase;
  padding: 0.4em 1em;
}

.addProductButton {
  composes: ui-button-clear from '../../../index.css';
  border: 0 none;
  padding: 0.55em 1em;
  text-transform: uppercase;
}

.addProductButtonRed {
  composes: addProductButton;
  margin: 0 auto;
  padding: 1em;
}

.addProductButtonBlue {
  composes: addProductButton;
  color: #fff;
  font-size: 1.5em;
}

.review .body {
  flex: 1;
  min-height: 10px;
  overflow: auto;
}

.noProductsMessage {
  color: #c0c0c0;
  font-size: 1.5em;
  padding: 8em 1em;
  text-align: center;
}

/* Smaller than 1024 */
@media only screen and (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
  }

  .leftContent,
  .rightContent {
    width: unset;
  }

  .rightContent {
    padding: 1em 1em 2em 1em;
  }
}


/* Smaller than 650 */
@media only screen and (max-width: 650px) {
  .header2Text,
  .addProductButtonBlue {
    font-size: 1.4em;
  }
}

/* Smaller than 500 */
@media only screen and (max-width: 500px) {
  .header2Text,
  .addProductButtonBlue {
    font-size: 1em;
  }

  .body {
    padding: 0 !important;
  }
}

/* Smaller than 435 */
@media only screen and (max-width: 435px) {
  .body {
   border: none;
  }

  .header2 {
    margin-bottom: 0;
  }
}

/* Smaller than 320 */
@media only screen and (max-width: 320px) {
  .body {
    margin-bottom: 8.5em !important;
  }
}