.logoSelector {
  composes: flex-row from '../../../../../index.css';
}

.logoSelectorHidden {
  composes: logoSelector;
  display: none;
}

.preview {
  composes: flex-one from '../../../../../index.css';
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  border: 1px solid #f0f0f0;
  margin: 0 0.5em 0 0;
  position: relative;
}

.thumbLoading {
  color: #f0f0f0;
  font-size: 50px;
  left: calc(50% - 25px);
  position: absolute;
  top: calc(50% - 25px);
}

.uploadOrSelect {
  composes: flex-one from '../../../../../index.css';
  composes: flex-column from '../../../../../index.css';
  position: relative;
}

.supportedFileTypes {
  font-family: 'proxima_novaregular';
  font-size: 0.7em;
  font-style: italic;
  line-height: 1.2em;
  padding: 1em 0 0 0;
}

.button {
  background-color: #fff;
  border: 3px solid #ccc;
  color: #575757;
  padding: 1em;
}

.uploadButton {
  cursor: pointer;
  position: relative;
}

.uploadButtonButton {
  composes: button;
  width: 100%;
}

.uploadField {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.or {
  height: 1em;
  padding: 0.5em;
  position: relative;
  text-align: center;
}

.or:before {
  border-bottom: 1px solid #ccc;
  content: "";
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
}

.orLabel {
  background-color: #fff;
  left: 50%;
  padding: 0 0.5em;
  position: absolute;
  transform: translateX(-50%);
}