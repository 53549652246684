.gray-goods {
  overflow: auto;
  height: 100%;
  /* padding: 50px 0 0 0; */
  position: relative;
  width: 100%;
}

.gray-goods .list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-around;
  overflow: auto;
  width: 100%;
}

.gray-goods .list .item {
  margin: 2em 0 2em 0;
  flex: 1 0 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1em;
}

.gray-goods .list .item,
.gray-goods .slider .item {
  cursor: pointer;
  pointer-events: all;
  text-align: center;
}

.gray-goods .item .thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 260px;
  min-width: 260px;

  -webkit-transition: height 0.5s, min-width 0.5s;
  -moz-transition: height 0.5s, min-width 0.5s;
  -o-transition: height 0.5s, min-width 0.5s;
  transition: height 0.5s, min-width 0.5s;
  transition-timing-function: ease-in-out;
}

.gray-goods .item .thumb:hover {
  opacity: 0.75;
}

.gray-goods .active .item .thumb {
  height: 250px;
  min-width: 250px;
}

.gray-goods .item .name {
  color: #575757;
  font-size: 1.5em;
  margin: 0.5em 0 0 0;
  text-transform: uppercase;
  -webkit-transition: font-size 0.5s;
  -moz-transition: font-size 0.5s;
  -o-transition: font-size 0.5s;
  transition: font-size 0.5s;
  transition-timing-function: ease-in-out;
  line-height: 1.25em;
  margin-top: 1em;
}

.gray-goods .active .item .name {
  font-weight: 600;
}

.mobile-list-display {
  padding: 1.5em 0 0 0;
  text-align: center;
}

.mobile-list-display>div.item {
  cursor: pointer;
  display: inline-block;
  margin: 0 2em 2em 2em;
}

.no-items-message {
  color: #c0c0c0;
  font-size: 1.5em;
  padding: 3em 2em 2em 2em;
  text-align: center;
}

.selected-filters {
  padding-left: 10px;
}

.selected-container {
  display: flex;
  flex-direction: row;
}

/* .reset-button {
  text-decoration: underline;
  margin-top: 0.5em;
  margin-bottom: 1em;
  cursor: pointer;
}

.filter-remove {
  cursor: pointer;
}

.filter-button {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cacaca;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.filter-button h2 {
  margin: 0;
  font-size: 14px;
  padding: 6px 10px;
}

.filter-button span {
  cursor: pointer;
  height: 30px;
  width: 25px;
  color: #fff;
  position: relative;
  left: 0px;
  top: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.graygoods-container {
  display: flex;
  flex-direction: row;
}

.filters-column {
  flex: 1 0 30%;
}

.graygoods-column {
  flex: 1 0 70%;
}

.edit-options {
  padding-top: 1em;
  margin: 2em;
  max-width: 24em;
  height: 100%;
  position: fixed;
  pointer-events: all;
  z-index: 1;
  width: 100%;
}

.filters-container {
  display: flex;
  flex-direction: column;
}

.divider {
  border-bottom: 1px solid #e7e4e0;
  margin-top: 1em;
}

.titleCheckmark {
  font-size: 1.2em;
  margin: 0;
  padding: 0.5em;
}

.title {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  flex-direction: row;
  margin: 3px 0 0 0;
  text-align: left;
}

.titleText {
  composes: flex-one from '../../../../index.css';
  padding: 0.5em 0;
}

h3 {
  padding-left: 10px;
}

.total-products {
  padding-left: 10px;
  padding-top: 1em;
}

@media only screen and (max-width: 1100px) {
  .gray-goods .item .thumb {
    width: 260px;
    height: 260px;
  }
}

@media only screen and (max-width: 875px) {
  .gray-goods .item .thumb {
    height: 150px;
    min-width: 150px;
  }

  .gray-goods .item .name {
    font-size: 1.25em;
    margin: 3em 0 0 0;
  }

  .gray-goods .slider .active .name {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 825px) {
  .gray-goods .slider .item .thumb {
    height: 100px;
    min-width: 100px;
  }

  .gray-goods .slider .active .thumb {
    height: 150px;
    min-width: 150px;
  }

  .gray-goods .item .name {
    font-size: 1em;
    margin: 4.5em 0 0 0;
  }

  .gray-goods .slider .active .name {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 750px) {
  .gray-goods .list {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
  }

  .gray-goods .list .item .thumb {
    height: 200px;
    min-width: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .gray-goods .item .name {
    font-size: 1.2em;
    margin: 2em 0 0 0;
  }
}

/* ----------------------------------------------------------------- */


@media (min-width: 751px) and (max-width: 1024px) {
  .filters-column {
    flex: 1 0 40%;
  }

  .graygoods-column {
    flex: 1 0 60%;
  }

  .edit-options {
    width: unset !important;
    position: relative;
  }
}

@media only screen and (max-width: 750px) {
  .graygoods-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filters-column, .graygoods-column {
    width: unset !important;
  }

  .filters-column {
    padding: 0 3em 0 3em;
  }

  .edit-options {
    position: relative;
    max-width: unset;
    margin: 0;
  }
}