.logoOrText {
  composes: flex-row from '../../../../../index.css';
}

.logoOrTextOr {
  composes: flex-one from '../../../../../index.css';
  padding: 1em;
  text-align: center;
}

.logoPlacement {
  composes: flex-row from '../../../../../index.css';
  align-items: center;
}

.logoPlacementHidden {
  display: none;
}

.radioField {
  composes: flex-one from '../../../../../index.css';
  padding: 1em;
}