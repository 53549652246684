.orderItemHeader {
  composes: flex-row from '../../../index.css';
}

.orderItem {
  composes: flex-row from '../../../index.css';
  color: #999;
  cursor: pointer;
  font-family: 'proxima_novaregular';
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.orderItem:hover {
  background-color: #f0f0f0;
}

.column {
  composes: flex-one from '../../../index.css';
  padding: 0.5em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageColumn {
  composes: column;
  background-position: center;
  background-repeat: no-repeat;
  height: 70px;
  min-height: 70px;
  object-fit: contain;
}

.thumbListPreview {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 25%;
}

.header {
  composes: column;
  color: #575757;
}

.mobileHeader {
  composes: header;
  display: none;
}

.noItemsFound {
  font-family: 'proxima_novaregular';
  color: #c0c0c0;
  font-size: 1.5em;
  padding: 3em 2em 2em 2em;
  text-align: center;
}

.thumbLoading {
  color: #f0f0f0;
  font-size: 50px;
}

.spinnerContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  row-gap: 0.25em;
}

.blur {
  filter: blur(3px);
  height: 70px;
  min-height: 70px;
  object-fit: contain;
}

.fadeIn {
  animation: fadeInAnimation 2s;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Smaller than 500 (Phone) */
@media only screen and (max-width: 500px) {
  .header {
    justify-content: center;
    align-items: center;
  }

  .imageColumn {
    height: 50px;
    min-height: 50px;
  }

  .column {
    padding: 0.25em;
    font-size: 0.7em;
  }
}