.confirmation {
  padding: 0 2em 2em 2em;
  text-align: center;
}

.header {
  font-size: 1.2em;
  margin: 0 0 1em 0;
}

.secondaryColorText {
  margin: 1.5em;
}

.primaryColorText {
  margin: 1.5em;
}

.button {
  border: 0 none;
  color: #fff;
  display: inline-block;
}