.bumpArrows {
  composes: flex-row from '../../../../../index.css';
  align-items: center;
  padding: 1em 1em 1em 0;
}

.upDownWrap {
  composes: flex-column from '../../../../../index.css';
  align-items: center;
}

.label {
  color: #575757;
}

.labelDisabled {
  composes: label;
  opacity: 0.5;
}

.arrow {
  cursor: pointer;
  padding: 1em;
}

.arrowDisabled {
  opacity: 0.5;
  padding: 1em;
}

.arrowDown {
  padding: 1em 1em 0 1em;
}

.arrowLeft {
  padding: 1em 1em 1em 0;
}

.arrowRight {
  padding: 1em 0 1em 1em;
}

.arrowUp {
  padding: 0 1em 1em 1em;
}

.arrowIcon {
  border: 1em solid #f0f0f0;
  border-radius: 2px;
  height: 0;
  width: 0;
}

.arrow .arrowIcon:hover {
  border-color: #d42b1e;
}

.arrowDown .arrowIcon {
  border-top: 1em solid #575757;
}

.arrowLeft .arrowIcon {
  border-right: 1em solid #575757;
}

.arrowRight .arrowIcon {
  border-left: 1em solid #575757;
}

.arrowUp .arrowIcon {
  border-bottom: 1em solid #575757;
}