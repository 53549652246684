.message {
  font-family: 'proxima_novaregular';
  padding: 2em 0 0 0;
}

.errorMessageContainer {
  color: #fff;
  text-align: center;
  background-color: #d42b1e;
  padding: 0.5em;
  margin: 0 0 2em 0;
}

.errorMessage {
  font-family: 'proxima_novaregular';
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inputContainer {
  padding-bottom: 2em;
  text-transform: uppercase;
}

.main {    
  margin: 0 2em 2em 2em;
}

.parentContent {
  display: flex;
  flex-direction: column;
}

.parentContent .input {
  margin: 0 0 1.7em 0;
  text-transform: uppercase;
}

.parentContent .input label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  width: 40%;
  color: #575757;
  padding: 0 1em 0 0;
}

.parentContent .field {
  align-items: stretch;
}

.parentContent .field label {
  text-transform: uppercase;
}

.parentContent .input input {
  border: 1px solid #ccc;
  color: #575757;
  flex: 1 1;
  outline: none;
  padding: .5em;
  width: 60%;
}

.parentContent .input .textDanger {
  font-family: 'proxima_novaregular';
  color: red;
  font-style: italic;
  font-size: 0.8em;
  position:absolute;
  right: 30px;
  text-transform: none;
  margin-top: 2px;
}

.confirmButton {
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1.5em;
  box-shadow: none;
  height: 38px;
  min-width: 186px;
  margin-top: 1em;
}

.confirmButton span {
  color: #fff;
}

.confirmButton .icon {
  font-size: 1.2em;
}