.customize-tools-hud {
  display: flex;
  flex-direction: row;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 2em;
}

.customize-tools-hud .button {
  background-color: #fff;
  border-radius: 0.2em;
  font-size: 1.75em;
  padding: 0.25em;
  margin: 0.25em;
  pointer-events: all;
}

@media only screen and (max-width: 875px) {
  .customize-tools-hud {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .customize-tools-hud {
    top: 0.5em
  }
}

/* Smaller than 435 (Phone) */
@media only screen and (max-width: 435px) and (orientation: portrait), (max-height: 435px) and (orientation: landscape) {
  .customize-tools-hud .button {
    font-size: 1.25em;
    margin: 0.5em 0.5em 0 0.5em;
    padding: 0.5em;
  }
}