.nameNumber {
  overflow: auto;
}

.field {
  margin: 0.5em 0;
}

.nameNumber .field label {
  text-align: right;
  text-transform: uppercase;
  width: 10em;
}