.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 0.75rem;
  width: 9em;
  height: 24px;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.arrow {
  width: 100%;
  height: 50%;
}

.arrow.top {
  transform: skew(45deg, 0deg);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
}

.arrow.bottom {
  transform: skew(-45deg, 0deg);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.content {
  position: absolute;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 7.75em;
  text-align: center;
}