.main {
  color: #404040;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
}

/*
Need this.
To keep prevent canvas stretching we hide Customize with absolute position
and height, with at 100%.
*/
.main-panels {
  position: relative;
}

.main-header {
  z-index: 1000;
}

.main .loading-order>span.icon {
  margin-right: 5px;
}

.main .loading-order {
  text-transform: capitalize;
  display: none;
  line-height: 23px;
  height: 23px;
  font-size: 1.5em;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100vh;
  left: 0;
  overflow: hidden;
  pointer-events: all;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}