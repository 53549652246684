.rosterItemEditor {
  height: 100%;
  margin: 0.5em 0;
}

.field {
  margin: 0.5em;
}

.rosterItemEditor label {
  text-align: right;
  text-transform: uppercase;
  width: 10em;
  color: #575757;
  padding: 0 1em 0 0;
}

.rosterItemEditor input {
  border: 1px solid #ccc;
  color: #575757;
  flex: 1 1;
  outline: none;
  padding: 0.5em;
}

.doneButtonRow {
  padding: 0.5em;
  display: flex;
  justify-content: center;
}

.doneButton {
  color: #fff;
  border-style: solid;
  border-width: 2px;
  display: inline-block;
  margin: 0.5em 0 0.5em 1em;
  text-transform: uppercase;
  width: 120px;
  height: 38px;
}