.trigger {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #919191;
  height: 0;
  outline: none;
  pointer-events: none;
	position: absolute;
	right: 7px;
  top: calc(50% - 2px);
  width: 0;
}

.selectSelected {
  composes: flex-one from '../../../../index.css';
  background-position: 0.5em center;
  background-repeat: no-repeat;
  background-size: calc(100% - 2em);
  border: 1px solid #ccc;
  color: #575757;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  padding: 0.5em 1.5em 0.5em 0.5em im !important;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder {
  color: #c0c0c0;
}