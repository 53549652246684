.mainColorSelector {
  min-height: 94px;
  z-index: 1;
}

.mainColorSelectorHidden {
  composes: mainColorSelector;
  display: none;
}

.bodyPopout {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;

  -moz-transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
  -webkit-transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
  transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
}

.toggleBar {
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  margin: 0 0 0.5em 0;
  text-align: center;
}

.toggleBarHidden {
  display: none;
}

.toggleIcon {
  color: #c0c0c0;
  font-size: 2em;
}

.header {
  composes: flex-row from '../../../../index.css';
  font-size: 0.8em;
  padding: 7px 7px 0 7px;
}

.radioField {
  color: #c0c0c0;
  font-size: 12px;
  padding: 0 1em;
}

.radioField:hover {
  color: #404040;
}

.radioFieldBold {
  composes: radioField;
  color: #404040;
}

.brushName {
  color: #404040;
  padding: 0 2em;
  text-transform: uppercase;
}