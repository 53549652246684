.reviewItem {
  padding: 1em;
}

.reviewItemMobile {
  padding: 0 1em 0 1em;
  border: 1.2px solid #D5D9D9;
  border-radius: 4px;
}

.itemHeader {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
}

.designStyle {
  color: #999;
  font-size: 1em;
  text-transform: uppercase;
}

.grayGood {
  flex: 2;
  font-size: 1.2em;
  margin: 0 1em 0 0;
}

.itemNumber {
  color: #999;
  font-family: 'proxima_novaregular';
  font-size: 0.9em;
  font-style: italic;
  text-transform: uppercase;
}

.quantity {
  color: #999;
  font-family: 'proxima_novaregular';
  font-size: 0.9em;
  font-style: italic;
  text-transform: uppercase;
}

.deleteButton {
  background-color: transparent;
  border: 0 none;
  padding: 1em 0;
  text-transform: uppercase;
}

.price {
  color: #ccc;
  flex: 1;
  font-size: 1.2em;
  margin: 0 1em 0 0;
  white-space: nowrap;
}

.subTotalLabel {
  color: #999;
  font-size: 1.2em;
  white-space: nowrap;
}

.subTotalValue {
  color: #02be02;
}

.body {
  display: flex;
  flex-direction: row;
}

.columnOne {
  flex: 2 1;
}

.columnTwo {
  flex: 3 1;
  margin: 0.5em;
}

.bodyColumnOne {
  composes: columnOne;
}

.editDesignButton {
  composes: ui-button-clear from '../../../index.css';
  margin: 0 auto;
}

.bodyEditDesignButton {
  composes: editDesignButton;
}

.footerEditDesignButton {
  composes: editDesignButton;
  font-size: 16px;
}

.editRosterButton {
  composes: ui-button-clear from '../../../index.css';
  margin: 0 auto;
  font-size: 16px;
}

.deleteProductButton {
  composes: ui-button-clear from '../../../index.css';
  margin: 0 auto;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.imgContainer img {
  height: auto;
  width: 50%;
}

.imgContainerMobile {
  font-size: 0;
  text-align: center;
  width: 300px;
  height: 250px;
}

.imgContainerMobile img {
  display: inline-block;
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  height: 250px;
  object-fit: unset;
}

.rosterGridTable {
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 300px;
  overflow: auto;
  width: 60%;
}

.removeItem {
  display: flex;
  justify-content: flex-end;
}

.thumbLoading {
  color: #f0f0f0;
  font-size: 50px;
  left: calc(50% - 25px);
  position: absolute;
  top: calc(50% - 25px);
}

.footer {
  composes: flex-row from '../../../index.css';
}

.colSide {
  display: flex;
  flex: 0 0 150px;
  justify-content: center;
}

.colSide>button {
  font-size: 20px;
}

.mobileFooterButtons {
  display: flex;
  justify-content: left;
  border-top: 1px solid #e7e4e0;
}

.mobileFooterButtons button {
  margin: 0 !important;
  text-transform: none;
  font-size: 16px;
}

.mobileFooterButtons .editDesignButton {
  padding-left: 0px;
}

.reviewContainer {
  display: flex;
  flex-direction: row;
}

.reviewContainer .productDescription {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}

.reviewContainer .productDescription .grayGood {
  margin: 1.5em 0 0 0;
}

.reviewContainer .productDescription .designStyle {
  margin: 0 0 1em 0;
}

.reviewContainer .pruductPrice {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.reviewContainer .pruductPrice .price {
  margin: 0;
}

.reviewContainer .thumbFront {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewContainer .thumbFront img {
  width: 75%;
  margin-top: 1em;
  margin-left: 1em;
}

.thumbFront {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rosterGridContainer {
  padding-bottom: 1em;
}

.rosterGridContainer table {
  margin: 0 auto;
  width: 100%;
}

.reviewContainer .divider {
  border-bottom: 1px solid #e7e4e0;
  margin-bottom: 1em;
  width: 80%;
}

.productDivider {
  border-bottom: 1px solid #e5e7ea;
  margin-left: 1em;
  margin-right: 1em;
}

.reviewItem {
  border-bottom: 1px solid #e5e7ea;
  margin-left: 1em;
  margin-right: 1em;
}

.reviewItem:last-child {
  border-bottom: 0 none;
}

span.separator {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #999;
}

.mobileFooterButtons .footerEditDesignButton,
.mobileFooterButtons .editRosterButton,
.mobileFooterButtons .deleteButton {
  flex-grow: 1;
}

.mobileFooterButtons .deleteButton {
  padding-right: 0px;
}

.mobileFooterButtons .dotsButton {
  flex-grow: 8;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0 none;
  padding: 1em 0 1em 0;
}

.reviewItemMobile .sizesSummary {
  color: #999;
  font-size: 0.9em;
  padding-bottom: 0.5em;
  text-align: left;
  padding-top: 1.5em;
}

.reviewItemMobile .sizesSummary .sizes {
  font-size: 1em;
  padding-left: 5px;
  color: #575757;
  font-family: 'proxima_novaregular';
}

.noRosterWarning {
  flex-direction: column;
  row-gap: 2.5em;
}

.warningMsg i {
  margin-right: 0.5em;
}

.warningMsg {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-family: 'proxima_novaregular';
}

.underline {
  text-decoration: underline;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  width: 100%;
  height: 50px;
  margin: 0;
  min-width: 50px;
  width: 50px;
  display: flex;
  flex-direction: row;
  border: 1.5px solid rgb(204, 204, 204);
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

.colorsCarousel {
  display: flex;
  flex-direction: row;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
}

.containerSkeleton {
  display: flex;
  flex-direction: row;
  column-gap: 0.5em;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
  width: 100%;
}


@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@media (min-width: 750px) and (max-width: 1024px) {
  .colSide {
    display: none;
  }

  .body {
    flex-direction: column;
  }

  .bodyColumnOne {
    border: 0 none;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1em;
  }

  .rosterGridTable {
    width: 100%;
  }

  .bodyEditDesignButton {
    display: none;
  }

  .colSide {
    display: none;
  }

  .deleteButton {
    display: block;
    margin: 0 !important;
    font-size: 16px;
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: unset;
  }

  .footer>.columnOne, .footer>.columnTwo {
    flex: none;
  }

  .display-roster-grid {
    margin-top: 20px;
  }
}

.fadeIn {
  animation: fadeInAnimation 2s;
}

.blur {
  filter: blur(3px);
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Larger than 750 */

@media only screen and (min-width: 751px) {
  .bodyColumnOne, .columnOne {
    display: flex;
    justify-content: center;
  }

  .columnOne {
    margin-right: 1em;
  }

  .bodyEditDesignButton {
    display: none;
  }
}

/* Smaller than 750 */

@media only screen and (max-width: 750px) {
  .warningMsg {
    font-size: 1em;
  }

  .reviewItem {
    margin: 1em 0;
  }

  .reviewItemMobile {
    margin: 1em 0;
  }

  .body {
    flex-direction: column;
  }

  .bodyColumnOne {
    border: 0 none;
    display: flex;
    justify-content: center;
    flex: none;
  }

  .bodyEditDesignButton {
    display: none;
  }

  .colSide {
    display: none;
  }

  .deleteButton {
    display: block;
    margin: 0 !important;
    font-size: 16px;
    padding: 1em;
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: unset;
  }

  .footer>.columnOne, .footer>.columnTwo {
    flex: none;
  }

  .bodyColumnOne {
    border: 0 none;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1em;
  }

  .rosterGridTable {
    width: 100%;
  }

  .columnTwo {
    margin: 0;
  }

  .display-roster-grid {
    margin-top: 20px;
  }

  .imgContainer {
    margin: 10px;
    height: unset;
  }
}

/* Smaller than 650 */

@media only screen and (max-width: 650px) {
  .grayFood, .price, .subTotal {
    font-size: 1em;
  }

  .designStyle {
    font-size: 0.9em;
  }

  .itemNumber {
    font-size: 0.8em;
  }
}

/* Smaller than 500 */

@media only screen and (max-width: 500px) {
  .reviewItem {
    margin: 1em;
    padding: 0;
  }

  .grayFood, .price, .subTotal {
    font-size: 0.8em;
  }

  .designStyle, .itemNumber {
    font-size: 0.8em;
  }

  .footerEditDesignButton {
    display: block;
  }

  .bodyColumnOne {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    ;
  }

  .imgContainer {
    width: unset;
  }

  .body {
    border: none;
  }
}