.orders {
  height: 95.5%;
  min-width: 20em;
  overflow: auto;
  padding: 1em;
}

.hidden {
  display: none;
}

.overflowHidden {
  overflow: hidden;
}