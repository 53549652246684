.savedOrders {
  height: 100%;
  min-width: 20em;
  overflow: auto;
  padding: 1em;
}
.hidden {
  display: none;
}

.savedOrders span:nth-child(4) {
  display: none;
}