.display-roster-grid {
  border-collapse: collapse;
}

.display-roster-grid th {
  font-family: 'proxima_novalight';
  font-style: italic;
  font-weight: 600;
  padding: 1px 1px 0 1px;
}

.display-roster-grid th,
.display-roster-grid td {
  justify-content: center;
  padding: 0.5em;
  text-align: center;
  white-space: normal;
}

.display-roster-grid tr td:not(:last-child),
.display-roster-grid tr th:not(:last-child) {
  border-right: 1px solid #c0c0c0;
}

.display-roster-grid tr:nth-of-type(even) {
  background-color: #f0f0f0;
}

@media only screen and (max-width: 500px) {
  .display-roster-grid th,
  .display-roster-grid tr {
    font-size: 0.9em;
  }
}