.disabled {
  opacity: 0.5;
}

.plusMinusInput {
  composes: flex-row from '../../../../index.css';
  align-items: center;
  border: 0 none;
  padding: 0;
}

.plusMinusIcon {
  color: #ccc;
  cursor: pointer;
  font-size: 2em;
}

.disabled .plusMinusIcon {
  cursor: default;
}

.plusMinusValue {
  min-width: 3.5em;
  padding: 0.25em 0.25em;
  text-align: center;
}