@font-face {
    font-family: 'proxima_novabold';
    src: url("resources/fonts/proximanova-bold-webfont.eot");
    src: url("resources/fonts/proximanova-bold-webfont.eot?#iefix") format('embedded-opentype'),
        url("resources/fonts/proximanova-bold-webfont.woff2") format('woff2'),
        url("resources/fonts/proximanova-bold-webfont.woff") format('woff'),
        url("resources/fonts/proximanova-bold-webfont.ttf") format('truetype'),
        url("resources/fonts/proximanova-bold-webfont.svg#proxima_novabold") format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novalight';
    src: url("resources/fonts/proximanova-light-webfont.eot");
    src: url("resources/fonts/proximanova-light-webfont.eot?#iefix") format('embedded-opentype'),
        url("resources/fonts/proximanova-light-webfont.woff2") format('woff2'),
        url("resources/fonts/proximanova-light-webfont.woff") format('woff'),
        url("resources/fonts/proximanova-light-webfont.ttf") format('truetype'),
        url("resources/fonts/proximanova-light-webfont.svg#proxima_novalight") format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novaregular';
    src: url("resources/fonts/proximanova-reg-webfont.eot");
    src: url("resources/fonts/proximanova-reg-webfont.eot?#iefix") format('embedded-opentype'),
        url("resources/fonts/proximanova-reg-webfont.woff2") format('woff2'),
        url("resources/fonts/proximanova-reg-webfont.woff") format('woff'),
        url("resources/fonts/proximanova-reg-webfont.ttf") format('truetype'),
        url("resources/fonts/proximanova-reg-webfont.svg#proxima_novaregular") format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novasemibold';
    src: url("resources/fonts/proximanova-sbold-webfont.eot");
    src: url("resources/fonts/proximanova-sbold-webfont.eot?#iefix") format('embedded-opentype'),
        url("resources/fonts/proximanova-sbold-webfont.woff2") format('woff2'),
        url("resources/fonts/proximanova-sbold-webfont.woff") format('woff'),
        url("resources/fonts/proximanova-sbold-webfont.ttf") format('truetype'),
        url("resources/fonts/proximanova-sbold-webfont.svg#proxima_novasemibold") format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novathin';
    src: url("resources/fonts/proximanova-thin-webfont.eot");
    src: url("resources/fonts/proximanova-thin-webfont.eot?#iefix") format('embedded-opentype'),
        url("resources/fonts/proximanova-thin-webfont.woff2") format('woff2'),
        url("resources/fonts/proximanova-thin-webfont.woff") format('woff'),
        url("resources/fonts/proximanova-thin-webfont.ttf") format('truetype'),
        url("resources/fonts/proximanova-thin-webfont.svg#proxima_novathin") format('svg');
    font-weight: normal;
    font-style: normal;
}

html, body {
    height: 100vh;
    font-size: 14px;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    -webkit-font-variant-ligatures: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 400;
    font-stretch: normal;
    line-height: 1.2308em;
    font-family: "Open Sans", "Helvetica Neue", helvetica, arial, sans-serif;
}

body {
    font-family: 'proxima_novabold', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    user-select: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

#modalRoot,
#modalRoot>div {
    height: 100vh;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
}

button,
input {
    font-family: 'proxima_novabold', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 14px;
    font-style: normal;
}

.ui-button-clear {
    background-color: transparent;
    border: 0 none;
    color: #575757;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-one {
    flex: 1;
    min-height: 10px;
    min-width: 10px;
}

.hidden {
    display: none !important;
}

.page-header {
    font-size: 2em;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    white-space: nowrap;
    background: #FFFF;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    line-height: 2em;
}

/* Smaller than 435 (Phone) */
@media only screen and (max-width: 435px) and (orientation: portrait), (max-height: 435px) and (orientation: landscape) {
    .ui-large-only {
        display: none;
    }
}