.container {
    display: flex;
    flex-direction: column;
}

.container .body {
    padding: 1em;
    overflow: auto;
    text-align: center;
    font-size: 16px;
}

.container .twoButtons {
    display: flex;
    flex-direction: row;
    padding: 1em;
    column-gap: 1em;
}

.container .twoButtons button {
    flex: 1;
}

.container .oneButton {
    display: flex;
    flex-direction: row;
    padding: 1em;
    justify-content: center;
    align-items: center;
}

.container .cancelButton {
    margin-right: 5px;

}

.container .outlineBtn {
    background: #FFFFFF;
}

.container .confirmButton {
    margin-left: 5px;
}

.container .urlLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
}