.logoSelector {
  margin: 1em 0.5em 0 0.5em;
}

.selectPlacementLabel {
  margin: 1.5em 0 0.5em 0;
  text-align: center;
}

.selectPlacement {
  composes: flex-row from '../../../../../index.css';
  flex-wrap: wrap;
  margin: 1em 0.5em;
  text-align: center;
}

.selectBox {
  cursor: pointer;
  margin: 0.5em 0;
  padding: 1px;
  position: relative;
}

.selectBoxSelected {
  composes: selectBox;
}

.selectBoxSelected:after {
  background: rgba(0,0,0,0.1);
  background: linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 50%, rgba(255,255,255,1) 100%);
  bottom: -5px;
  box-shadow: inset 0 -1px 2px #fff;
  content: "";
  height: 5px;
  left: 2px;
  position: absolute;
  transform-origin: bottom;
  width: calc(100% - 20px);
  z-index: 2;
}

.checkbox {
  composes: flex-row from '../../../../../index.css';
  align-items: center;
  cursor: pointer;
  font-size: 0.8em;
  margin: 0.5em;
}

.checkboxDisabled {
  composes: checkbox;
  cursor: default;
  opacity: 0.5;
}

.checkboxInput {
  border: 3px solid #404040;
  height: 1em;
  position: relative;
  width: 1em;
}

.checkboxInputChecked {
  composes: checkboxInput;
}

.checkboxInputChecked:after {
  border-bottom: 3px solid #d42b1e;
  border-right: 3px solid #d42b1e;
  content: "";
  height: 1.5em;
  left: 0.6em;
  position: absolute;
  top: -0.95em;
  transform: rotate(40deg);
  width: 0.4em;
}

.checkboxLabel {
  margin: 0 0 0 0.5em;
  white-space: nowrap;
}