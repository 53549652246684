.field {
  margin: 0.5em 0;
}

.field label {
  text-align: right;
  text-transform: uppercase;
  width: 10em;
}

.summaryRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em 0 1em 0;
}

.totalRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.5em;
}

.totalCount {
  color: #333;
  font-family: 'proxima_novaregular';
  font-style: italic;
  margin: 0 1em 0 0;
}

.totalLabel {
  font-size: 1.5em;
  margin: 0 0.2em 0 0;
}

.totalValue {
  color: #02be02;
  font-size: 1.5em;
}

.spacer {
  composes: flex-one from '../../../index.css';
}

.productSummary {
  text-transform: uppercase;
  border-bottom: 1px solid #e5e7ea;
  padding-bottom: 1em;
  min-height: 70px;
  height: 100%;
}

.tdPadding {
  padding: 0 0.5em 0 0.5em;
}

.grayGood {
  composes: tdPadding;
}

.grayItalic {
  color: #404040;
  font-family: 'proxima_novaregular';
  font-style: italic;
}

.quantity {
  composes: grayItalic;
  composes: tdPadding;
}

.subTotal {
  composes: grayItalic;
  composes: tdPadding;
}

.taxLabel {
  composes: grayItalic;
  composes: tdPadding;
}

.taxValue {
  composes: grayItalic;
  composes: tdPadding;
}

.smallText {
  font-family: 'proxima_novaregular';
  font-style: italic;
  text-align: right;
}

.saveButtonsRow {
  padding: 1em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 -2px 5px -1px rgb(0 0 0 / 20%);
  background-color: #FFFF;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.saveButtonsRow button {
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saveButton {
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  margin-right: 1em;
  text-transform: uppercase;
  padding: 0.4em 1em;
}

.reviewFooter {
  border: 1.2px solid #D5D9D9;
  border-radius: 4px;
  padding: 1em;
  position: sticky !important;
  top: 1.5em;
}

.submitButton {
  color: #fff;
  border-style: solid;
  border-width: 2px;
  text-transform: uppercase;
  padding: 0.4em 1em;
}

.title {
  composes: flex-one from '../../../index.css';
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 0.5em 0 1em 0;
  border-bottom: 1px solid #e5e7ea;
}

.subtitle {
  font-size: 1.25em;
  text-transform: capitalize;
  padding: 0.5em 0 1em 0;
}

.shippingWrap {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #e5e7ea;
  padding-bottom: 1em;
}

.showShippingDetails {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}

.shippingHeader .subtitle {
  padding-right: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shippingHeader i {
  margin-bottom: 8px;
  cursor: pointer;
}

.shippingHeader {
  display: flex;
  align-items: center;
}

.summaryPrices {
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
  padding-top: 1em;
}

.importantText {
  text-transform: initial;
}

.disclaimer {
  border-top: 1px dashed rgba(128, 128, 128, 0.637);
  padding: 1.5em 0 2em 0;
}

.disclaimer div {
  margin-left: 2em;
  display: flex;
  flex-direction: column;
}

.disclaimer i {
  position: absolute;
  padding-top: 2px;
  font-size: 1.25em;
}

.summaryPrices .trRow div {
  display: inline-table;
  text-align: right;
  flex: 0 0 50%;
}

.summaryPrices .trRow td:nth-child(2) {
  flex: 0 0 50%;
  text-align: right;
}

tr.trRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

td.leftTd {
  text-align: right;
  flex: 0 0 50%;
}

.rigthTd {
  color: #404040;
  font-family: 'proxima_novaregular';
  font-style: normal;
}

td.rigthTd {
  text-align: right;
  flex: 0 0 50%;
}

.ml2 input {
  margin-left: 2em;
  width: 70%;
}

div.mt1 {
  margin-top: 0.5em;
}

.pt05 {
  padding-top: 0.5em;
}

.productSubtotal {
  border-top: 1px solid #e5e7ea;
  padding-top: 0.5em;
  margin-left: 5%;
  flex: 0 0 45% !important;
}

.deliveryMethodTxt {
  font-size: 1em;
  font-weight: 600;
  line-height: 2em;
  cursor: pointer;
}

.deliveryMethodTxt:hover {
  opacity: 0.75;
}

.freeShippingDescription {
  background-color: #f0f0f0;
  border-color: #f0f0f0e1;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-family: 'proxima_novaregular';
}

.lineThrough {
  text-decoration: line-through;
  color: #999;
}

.freeShipping {
  color: #00a650;
}

/* Smaller than 1400 and bigger than 1024  */
@media (min-width: 1024px) and (max-width: 1400px) {
  td.rigthTd {
    text-align: right;
    flex: 0 0 60%;
  }

  td.leftTd {
    text-align: right;
    flex: 0 0 40% !important;
  }

  .summaryPrices .trRow div {
    display: inline-table;
    text-align: right;
    flex: 0 0 60%;
  }

  .summaryPrices .trRow td:nth-child(2) {
    flex: 0 0 60%;
    text-align: right;
  }

  .productSubtotal {
    margin-left: 5%;
    flex: 0 0 55% !important;
  }
}

/* Smaller than 650 */
@media only screen and (max-width: 650px) {
  .title {
    font-size: 1.4em;
  }

  .ml2 input {
    margin-left: 2em;
    width: 70%;
  }
}

/* Smaller than 500 */
@media only screen and (max-width: 500px) {
  .title {
    font-size: 1.25em;
  }

  .productSummary {
    font-size: 0.9em;
  }
}

/* Smaller than 1150  */
@media only screen and (max-width: 1150px) {
  .totalRow, .smallText {
    font-size: 0.9em;
  }

  .summaryRow {
    font-size: 0.9em;
  }
}


@media only screen and (min-width: 321px) and (max-width: 650px) {
  .saveButtonsRow {
    justify-content: space-between;
  }

  .saveButtonsRow button {
    white-space: nowrap;
    font-size: 12px;
  }
}

@media only screen and (max-width: 320px) {
  .saveButtonsRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1 2em 1 2em;
  }

  .saveButtonsRow button {
    white-space: nowrap;
    font-size: 12px;
    margin-right: 0;
  }

  .saveButtonsRow .submitButton {
    margin-top: 10px;
  }
}