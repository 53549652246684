.colorsCarousel {
  display: flex;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
}

.colorsCarouselHidden {
  composes: colorsCarousel;
  display: none;
}

.colorsCarouselDisabled {
  composes: colorsCarousel;
  opacity: 0.5;
}

.colorChiclet {
  cursor: pointer;
  height: 63px;
  width: 63px;
  min-width: 63px;
  border: 1.5px solid rgb(204, 204, 204);
}

.reduced {
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
}

.patternInUseColorChiclet {
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
}

.patternReduced {
  height: 40px !important;
  width: 40px !important;
  min-width: 40px !important;
}


.inUseWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.25em;
  align-items: center;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.patternImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25em;
  padding: 0 0.5em;
}

.inUseSolidsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noClick {
  pointer-events: none !important;
  cursor: default !important;
}

.divider {
  border-left: 2px solid rgb(204, 204, 204);
  height: 50px;
  margin-left: 1em;
  margin-right: 0.5em;
  margin-bottom: 1.25em;
}

.inUseWrapper>span,
.inUseSolidsWrapper>span,
.patternImg>span {
  font-size: 0.75em;
  font-family: 'proxima_novalight';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
  max-width: 50px;
  text-align: center;
  height: 1.5em;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
  width: 100%;
}

.inUseContainer {
  column-gap: 0.75em;
}