.confirmation {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.message {
  color: #777;
  font-family: 'proxima_novaregular';
  text-align: center;
  text-transform: uppercase;
  width: 300px;
}

.emphasize {
  color: #404040;
  font-family: 'proxima_novabold';
}

.loggedIn {
  composes: emphasize;
  font-size: 1.2em;
  margin: 0 0 1em 0;
}