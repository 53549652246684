.selected {
  box-shadow: 0px 0px 5px #000;
  outline: 2px solid #fff;
  z-index: 2;
}

.checkmark {
  color: #fff;
  font-size: 2em;
  left: 0.5em;
  position: absolute;
  text-shadow: 0px 0px 2px #404040;
  top: 0.65em;
}

.checkmarkHidden {
  display: none;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 3;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.selectedChiclet {
  border: '15px solid black;'
}

.skeletonAnimation {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  width: 100%;
  height: 63px;
  margin: 0;
  min-width: 63px;
  width: 63px;
  display: flex;
  flex-direction: row;
  border: 1.5px solid rgb(204, 204, 204);
}

.skeletonAnimation::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

.diagonal:after {
  content: ''; 
  height: 1px;
  width: 141.421%;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0%; 
  transform: rotate(45deg);
  transform-origin: 1px 1px;
}

.diagonal {
  border: 1.5px solid black !important;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}