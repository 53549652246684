.customize-tabs .tab-bar {
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.customize-tabs .tab-body {
  overflow: auto;
}

/* start: tab */

.customize-tabs .tab-bar .tab {
  background-color: #ccc;
  cursor: pointer;
  flex: 1 0 auto;
  min-width: 10px;
  position: relative;
}

.customize-tabs .tab-bar .tab .label {
  color: #fff;
  flex: 1;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 30px 10px 10px;
  white-space: nowrap;
  z-index: 2;
}

.customize-tabs .tab-bar .tab:not(:first-child).active .label:after {
  left: -24px;
  width: calc(100% + 2px);
}

.customize-tabs .tab-bar .tab.disabled .label {
  color: #ccc;
}

/* end: tab */

/* start: tab arrow */

.customize-tabs .tab-bar .tab .arrow {
  background-color: #ccc;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 30px;
}

.customize-tabs .tab-bar .tab:last-child .label {
  padding: 10px;
}

.customize-tabs .tab-bar .tab:last-child .arrow {
  display: none;
}

.customize-tabs .tab-bar .tab .arrow-inner {
  background-color: #ccc;
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding-top: 50px;
  position: absolute;
  right: 11px;
  top: -7px;
  transform: rotate(-45deg);
  width: 50px;
}

/* end: tab arrow */

/* start: tab body */

.customize-tabs .tab-body > * {
  display: none;
}

.customize-tabs.active-tab-1 .tab-body > *:nth-child(1),
.customize-tabs.active-tab-2 .tab-body > *:nth-child(2),
.customize-tabs.active-tab-3 .tab-body > *:nth-child(3) {
  display: block !important;
}

/* end: tab body */