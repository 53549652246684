.button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 1em;
  line-height: 1em;
  margin: 0;
  min-width: 0;
  outline: 0;
  overflow: hidden;
  padding: 1em;
  border-radius: 4px;
}

.button:hover {
  opacity: 0.75;
}

.button:disabled {
  cursor: auto !important;
  opacity: 0.6 !important;
}

.button.center-text {
  margin-left: auto;
  margin-right: auto;
}

.button.has-label .label {
  flex: 1;
}

.button.has-icon:not(.has-label) .icon {
  flex: 1;
}

.button:not(.has-icon) .icon {
  display: none;
}

.button.has-icon.has-label .label {
  margin: 0 0 0 0.5em;
}

.button.has-icon.has-label.align-icon-right {
  flex-direction: row-reverse;
}

.button.has-icon.has-label.align-icon-right .label {
  margin: 0 0.5em 0 0;
}

.button.ui-clear {
  background-color: transparent;
  border: 0 none;
  color: #575757;
}

.button.ui-gray {
  background-color: #a0a0a0;
  border: 0 none;
  color: #fff;
  padding: 0.5em 1.5em;
}

.button.ui-link {
  padding: 0;
  background: none;
  color: darkslateblue;
  text-decoration: underline;
  border: none;
  font-weight: normal;
}

.button.ui-primary {
  border: 0 none;
  color: #fff;
  padding: 0.5em 1.5em;
}

.btn-primary {
  line-height: 24px;
}
