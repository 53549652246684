.field {
  align-items: center;
  position: relative;
}

.fieldRow {
  composes: flex-row from '../../../../index.css';
  composes: field;
}

.fieldColumn {
  composes: flex-column from '../../../../index.css';
  composes: field;
}

.field label {
  color: #575757;
  padding: 0 1em 0 0;
}

.field input {
  border: 1px solid #ccc;
  color: #575757;
  flex: 1;
  outline: none;
  padding: 0.5em;
}

/* Chrome, Safari, Edge, Opera */
.field input::-webkit-outer-spin-button,
.field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.field input[type=number] {
  -moz-appearance: textfield;
}