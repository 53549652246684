.container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
}

.container .body {
  padding: 1em;
  font-size: 1em;
}

.container .prices {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  row-gap: 0.25em;
}

.body h4 {
  font-weight: 600;
}

.body span {
  font-family: 'proxima_novaregular';
}