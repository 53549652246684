.loginMain {
  composes: loginMain from './Login.module.css';
}

.loginMainTitle {
  composes: loginMainTitle from './Login.module.css';
}

.message {
  font-family: 'proxima_novaregular';
  padding: 0 0 2em 0;
  text-align: center;
}

.smsIcon {
  font-size: 3em;
  padding: 0 0 0.5em 0;
}

.linkSentMessage,
.smsSentMessage {
  padding: 0;
}

.sendNewCode {
  cursor: pointer;
  padding: 0.5em;
  text-decoration: underline;
}

.errorMessageContainer {
  composes: errorMessageContainer from './Login.module.css';
}

.errorMessage {
  composes: errorMessage from './Login.module.css';
}

.loginFailedHidden {
  composes: loginFailedHidden from './Login.module.css';
}

.inputContainer {
  composes: inputContainer from './Login.module.css';
}

.loginField {
  composes: loginField from './Login.module.css';
}

.buttonContainer {
  composes: buttonContainer from './Login.module.css';
}

.loginButtonRed { 
  composes: loginButtonRed from './Login.module.css';
}