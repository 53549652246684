.moreMenu {
  width: 21em;
}

.moreMenu .moreMenuButtons {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.moreMenu .moreMenuButtons button > span:nth-child(1) {
  font-size: 1.5em;
}

.moreMenu .moreMenuButtons button:hover>span:nth-child(1) {
  transform: scale(1.1);
}

.moreMenu .moreMenuButtons button:hover>span  {
  filter: brightness(70%);
}

.moreMenu .moreMenuButtons button {
  transition: all 0.1s ease-in-out;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
  width: 100%;
  margin: 0.75em 0;
}

.moreMenu .icon {
  font-size: 1.25em !important;
}

.logoutButtonRow {
  padding: 0 0 0.5em 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}

.logoutButton {
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1.5em;
  height: 38px;
}

.moreMenuButton {
  composes: ui-button-clear from '../../../index.css';
  text-transform: uppercase;
}

.buttonNote {
  color: #d42b1e;
  font-family: 'proxima_novaregular';
  font-size: 0.7em;
  font-style: italic;
  padding: 0 1.3em 1.3em 3.5em;
  text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
  .moreMenu {
    width: 100%;
  }
}