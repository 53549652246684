.title {
  align-items: center;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  flex-direction: row;
  margin: 3px 0 0 0;
  text-align: left;
}

.titleCheckmark {
  font-size: 1.2em;
  margin: 0;
  padding: 0.5em;
}

.titleCollapse {
  font-size: 1.2em;
  margin: 0;
  padding: 0.5em;
}

.titleText {
  composes: flex-one from '../../../../index.css';
  padding: 0.5em 0;
}

.bodyCollapsed {
  display: none;
}