#root {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 100%;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.App {
    height: 100%;
}
