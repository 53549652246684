.radioOptions {
  composes: flex-row from '../../../../index.css';
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orLabel {
  composes: flex-one from '../../../../index.css';
  padding: 1em;
  text-align: center;
}

.radioField {
  composes: flex-one from '../../../../index.css';
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field label {
  text-align: right;
  text-transform: uppercase;
  width: 10em;
}