.whiteHeaderWrapper {
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.whiteHeader {
  composes: flex-row from '../../../index.css';
  align-items: center;
  justify-content: space-between;
}

.whiteHeader img {
  margin-left: 1em;
}

.version {
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.divider {
  font-size: 2.5em;
  font-family: 'proxima_novalight';
}

.userButton {
  composes: ui-button-clear from '../../../index.css';
  cursor: pointer;
  padding: 0.5em 1em;
}

.mobileNavOptions {
  font-size: 0.85em !important;
  margin-left: 1em;
}

.mobileOffline {
  font-size: 1.1em !important;
}

/* Smaller than 435 (Phone) */
@media only screen and (max-width: 435px) {
  .userButton {
    font-size: 1.2em;
  }
}

/* Smaller than 450 and portrait */
@media only screen and (max-width: 450px) and (orientation: portrait) {
  .version {
    display: none;
  }
}